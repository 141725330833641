<template>
	<div class="opacity-page report-money" v-if="loaded">
		<div class="header-filter padd-b-0">
			<div class="row-space-tbf search-row">
				<div class="space-left"></div>
				<div class="content">
					<div class="actions-page">
						<div class="filter-dropdown">
							<div class="label">Interval</div>
							<div class="dropdown">
								<button class="btn-tbf white" id="dropdownFilterObjStatus" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text">{{ $t(`admin_users.report_interval.${selectedReport}`) }}</span>

									<div class="icon-filter"><icon-arrow /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilterObjStatus">
									<div class="dropdown-item" @click="selectIntervalReport('month')" v-bind:class="{active: selectedReport == 'month'}">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ $t(`admin_users.report_interval.month`) }}</span>
										</div>
									</div>
									<div class="dropdown-item" @click="selectIntervalReport('year')" v-bind:class="{active: selectedReport == 'year'}">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ $t(`admin_users.report_interval.year`) }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>

		<div class="cards-summary row-space-tbf">
			<div class="space-left"></div>
			<div class="content">
				<div class="card-item objectives">
					<div class="card-content">
						<div class="header">
							<div class="title">{{ $t(`admin_users.soft_name.${reportData.objective.name}`) }}</div>
							<div class="desc">{{ reportData.objective.total_accounts_active }} conturi active</div>
						</div>
						<div class="values">
							<div class="euro">{{ parseFloat(parseFloat(reportData.objective.total_soft_price_net / 4.94).toFixed(2)) | numeral('0,0') }} &euro;</div>
							<div class="ron">{{ parseFloat(parseFloat(reportData.objective.total_soft_price_net).toFixed(2)) | numeral('0,0') }} RON</div>
						</div>
					</div>
				</div>

				<div class="card-item procedures">
					<div class="card-content">
						<div class="header">
							<div class="title">{{ $t(`admin_users.soft_name.${reportData.procedure.name}`) }}</div>
							<div class="desc">{{ reportData.procedure.total_accounts_active }} conturi active</div>
						</div>
						<div class="values">
							<div class="euro">{{ parseFloat(parseFloat(reportData.procedure.total_soft_price_net / 4.94).toFixed(2)) | numeral('0,0') }} &euro;</div>
							<div class="ron">{{ parseFloat(parseFloat(reportData.procedure.total_soft_price_net).toFixed(2)) | numeral('0,0') }} RON</div>
						</div>
					</div>
				</div>

				<div class="card-item organigram">
					<div class="card-content">
						<div class="header">
							<div class="title">{{ $t(`admin_users.soft_name.${reportData.organigram.name}`) }}</div>
							<div class="desc">{{ reportData.organigram.total_accounts_active }} conturi active</div>
						</div>
						<div class="values">
							<div class="euro">{{ parseFloat(parseFloat(reportData.organigram.total_soft_price_net / 4.94).toFixed(2)) | numeral('0,0') }} &euro;</div>
							<div class="ron">{{ parseFloat(parseFloat(reportData.organigram.total_soft_price_net).toFixed(2)) | numeral('0,0') }} RON</div>
						</div>
					</div>
				</div>

				<div class="card-item total">
					<div class="card-content">
						<div class="header">
							<div class="title">Total</div>
							<div class="desc">{{ unique_accounts }} conturi unice</div>
						</div>
						<div class="values">
							<div class="euro">{{ parseFloat(parseFloat(total_sum / 4.94).toFixed(2)) | numeral('0,0') }} &euro;</div>
							<div class="ron">{{ parseFloat(parseFloat(total_sum).toFixed(2)) | numeral('0,0') }} RON</div>
						</div>
					</div>
				</div>
			</div>
			<div class="space-right"></div>
		</div>

		<template v-if="loadedList">
			<template v-for="soft in reportData">
				<div class="row-space-tbf tables-packages">
					<div class="space-left"></div>
					<div class="content">
						<div class="table-soft">
							<h2 class="title">{{ $t(`admin_users.soft_name.${soft.name}`) }}</h2>

							<div class="chart-packages">
								<apexchart type="bar" height="90" :options="chartOptions" :series="series[soft.name]" :key="`${soft.name}_${charts_key}`"></apexchart>
							</div>

							<div class="list-money-report">
								<div class="header-list">
									<div class="column-filter column-name">
										<div class="text">{{ $t('admin_users.name') }}</div>
									</div>
									<div class="column-filter column-accounts">
										<div class="text">{{ $t('admin_users.accounts') }}</div>
									</div>
									<div class="column-filter column-vouchers">
										<div class="text">{{ $t('admin_users.vouchers') }}</div>
									</div>
									<div class="column-filter column-price">
										<div class="text">{{ $t('admin_users.package_price') }}</div>
									</div>
									<div class="column-filter column-price">
										<div class="text">{{ $t('admin_users.vouchers_price') }}</div>
									</div>
									<div class="column-filter column-price">
										<div class="text">{{ $t('admin_users.total_price') }} RON</div>
									</div>
								</div>

								<template v-for="package_item in Object.values(soft.packages)">
									<div class="row-list-item">
										<div class="border-hover" v-if="$resize && $mq.above(781)"></div>
										<div class="column-name-title column-name">
											<div class="name">{{ package_item.name }}</div>
										</div>
										<div class="column-link column-accounts">
											<router-link :to="{ name:'admin-users', query: {status_objectives: 'active,grace_period', package_objectives: package_item.name}}" class="link" v-if="soft.name == 'objective'">{{ package_item.total_accounts_active }}</router-link>
											<router-link :to="{ name:'admin-users', query: {status_organigram: 'active,grace_period', package_organigram: package_item.name}}" class="link" v-else-if="soft.name == 'organigram'">{{ package_item.total_accounts_active }}</router-link>
											<router-link :to="{ name:'admin-users', query: {status_procedures: 'active,grace_period', package_procedures: package_item.name}}" class="link" v-else-if="soft.name == 'procedure'">{{ package_item.total_accounts_active }}</router-link>
										</div>
										<div class="column-link column-vouchers" @click="showModal('report_vouchers', { name: package_item.name, soft: soft.name, entries: package_item.vouchers })">
											<a class="link">{{ package_item.vouchers ? Object.values(package_item.vouchers).length : 0}}</a>
										</div>
										<div class="column-simple-text column-price">
											<div class="text">{{ parseFloat(parseFloat(package_item.package_price).toFixed(2)) | numeral('0,0') }}</div>
										</div>
										<div class="column-simple-text column-price">
											<div class="text">{{ parseFloat(parseFloat(package_item.total_vouchers_discount).toFixed(2)) | numeral('0,0') }}</div>
										</div>
										<div class="column-simple-text column-price">
											<div class="text">{{ parseFloat(parseFloat(package_item.total_price).toFixed(2)) | numeral('0,0') }}</div>
										</div>
									</div>
								</template>

								<div class="footer-list br-top">
									<div class="column-footer column-name">
										<div class="text">Total Brut RON</div>
									</div>
									<div class="column-footer column-price">
										<div class="text bold">{{ parseFloat(parseFloat(soft.total_soft_price).toFixed(2)) | numeral('0,0') }}</div>
									</div>
								</div>
								<div class="footer-list">
									<div class="column-footer column-name">
										<div class="text">Taxa Stripe (RON)</div>
									</div>
									<div class="column-footer column-price">
										<div class="text bold">{{ parseFloat(parseFloat(soft.stripe_fee).toFixed(2)) | numeral('0,0') }}</div>
									</div>
								</div>
								<div class="footer-list">
									<div class="column-footer column-name">
										<div class="text">Taxa retragere Stripe (RON)</div>
									</div>
									<div class="column-footer column-price">
										<div class="text bold">{{ parseFloat(parseFloat(soft.withdrawal_stripe_fee).toFixed(2)) | numeral('0,0') }}</div>
									</div>
								</div>
								<div class="footer-list br-top">
									<div class="column-footer column-name">
										<div class="text">Total RON</div>
									</div>
									<div class="column-footer column-price">
										<div class="text bold">{{ parseFloat(parseFloat(soft.total_soft_price_net).toFixed(2)) | numeral('0,0') }}</div>
									</div>
								</div>
								<div class="footer-list ">
									<div class="column-footer column-name">
										<div class="text">Total EURO (1 EURO = 4.94 RON)</div>
									</div>
									<div class="column-footer column-price">
										<div class="text bold">{{ parseFloat(parseFloat(soft.total_soft_price_net / 4.94).toFixed(2)) | numeral('0,0') }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</template>

		</template>
		<loader-items-list class="position-absolut-list-loader" v-else/>
	</div>
</template>

<script>
    import IconSearch from '../Icons/Search'
    import IconArrow from '../Icons/Arrow'
    import IconTrash from '../Icons/Trash'
    import IconUser from '../Icons/User'
    import IconPeople from '../Icons/People'
	import IconSettings from '../Icons/Settings'
	import IconClose from '../Icons/Close'
	import IconDate from '../Icons/Date'
    import LoaderItemsList from '../PagesLoaders/ItemsList'
	import InfiniteLoading from 'vue-infinite-loading'
	import VueApexCharts from 'vue-apexcharts'
	import { MonthPickerInput } from 'vue-month-picker'

    export default {
    	components: {
            IconSearch,
            IconArrow,
            IconTrash,
            IconUser,
            IconPeople,
			IconSettings,
			IconClose,
			IconDate,
           	LoaderItemsList, 
			InfiniteLoading,
			apexchart: VueApexCharts,
			MonthPickerInput
        },
        data() {
            return {
            	loaded: false,
				loadedList: false,
            	reportData: {},
            	total_sum: 0,
            	unique_accounts: 0,
            	series: {
            		objective: [],
            		procedure: [],
            		organigram: [],
            	},
            	charts_key: 1,
            	chartOptions: {
            		chart: {
            			type: 'bar',
            			height: 90,
            			stacked: true,
            			stackType: '100%',
            			toolbar: {
            				show: false,
            			}
            		},
            		plotOptions: {
            			bar: {
            				horizontal: true,
            			},
            		},
            		stroke: {
            			width: 0
            		},
            		tooltip: {
            			y: {
            				formatter: function (val) {
            					return val
            				}
            			},
            			x: {
            				formatter: function (val) {
            					return 'Conturi active'
            				}
            			}
            		},
            		fill: {
            			opacity: 1
            		},
            		grid: {
            			show: false,
            		},
            		xaxis: {
            			labels: {
            				show: false
            			}
            		},
            		yaxis: {
            			labels: {
            				show: false
            			}
            		},
            		legend: {
            			position: 'top',
            			horizontalAlign: 'left',
            			offsetX: 0,
            			offsetY: 25

            		}
            	},
            	month_date: {},
            	status_report: 'all_time',
            	selectedReport: 'month'
            }
        },
        watch: {
        	month_date: function(val) {
        		var data = Object.assign({}, this.$route.query);
				data['report_monthly'] = `${val.year}-${val.monthIndex}`;
				this.$router.push({query : data });

				this.getReportMoney()
			},
			status_report: function(val) {
				if(val == 'all_time') {
					var data = Object.assign({}, this.$route.query);
					delete data['report_monthly']
					this.$router.push({query : data });

					this.getReportMoney()
				}
			},
			selectedReport: function(val) {
				this.getReportMoney()
			}
        },
        async mounted(){
			setTimeout(() => {
				var title = this.$t('navbar.admin_report');
				this.$root.$emit("navbar_title", title);
			}, 0)
				
			var dataQuery = Object.assign({}, this.$route.query);
			Object.keys(dataQuery).forEach(key => {
				if(key == 'report_monthly'){ 
					this.status_report = 'monthly'
					this.month_date.year = dataQuery[key].split('-')[0]
					this.month_date.monthIndex = dataQuery[key].split('-')[1]
				}
			});

			this.getReportMoney()
        },
        methods: {
        	getReportMoney() {
        		var paramsCall = {};
        		paramsCall.interval = this.selectedReport

				this.series = { objective: [], procedure: [], organigram: [] };

        		axios.get('/reports/overall-informations', { params: paramsCall })
        		.then(({data}) => {
        			this.reportData = data.data;
        			this.total_sum = data.total_amount
        			this.unique_accounts = data.unique_accounts

        			Object.values(this.reportData).map(soft => {
	        			Object.values(soft.packages).map(el => { 
	        				this.series[soft.name].push({ name: el.name, data: [el.total_accounts_active] })
	        			})
        			})
        		})
        		.finally(() => {
        			setTimeout(() => {
        				this.loaded = true
        				this.loadedList = true
        				setTimeout(() => {
        					$('.opacity-page').addClass('show')
        				}, 0)
        			}, 1000)
        		})
        	},
			syncList(){
				var buttonName = `buttonSync`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				axios.get('/overall')
				.then(() => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('admin_users.sync')
							btnSubmit.disabled = false

							this.searchFilterFunct()
						}, 300)
					})
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('admin_users.sync')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				})
			},
			populateMonthDate(data) {
				this.month_date = data
			},
			showModal(type, data = false){
				this.$root.$emit('open_modal', type, data);
			},
			selectIntervalReport(value) {
				this.selectedReport = value
			}
        }
    }
</script>

<style lang="scss">
    .list-money-report{
    	.row-list-item{
    		&:hover{
    			.content{
    				.column-name{
    					.name{
    						font-weight: 700;
    					}
    				}
    			}
    		}
    	}
    	.column-name{
    		flex: 1 1 auto;
    		&.link{
    			cursor: pointer;
    		}
    	}
    	.column-accounts{
    		flex: 0 0 150px;
    		justify-content: flex-end;
    		display: flex;
    		.text{
    			text-align: right;
    		}
    	}
    	.column-vouchers{
    		flex: 0 0 150px;
    		justify-content: flex-end;
    		display: flex;
    		.link{
    			text-align: right;
    		}
    	}
    	.column-price{
    		flex: 0 0 150px;
    		justify-content: flex-end;
    		.text{
    			text-align: right;
    		}
    	}
    }

    .report-money{
    	.title-report{
    		margin-left: auto;
    		font-weight: 500;
    		font-size: 18px;
    	}
    }
</style>